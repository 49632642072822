import { useTheme } from '@mui/styles';
import {
  On3Icon5GoatsLogo,
  On3IconHerLogo,
  On3IconHighSchoolLogo,
  On3IconNilLogo,
  On3IconOn3Elite,
  On3IconSoftballLogo,
  On3IconTransferPortal,
} from '@on3/icons';
import { On3 } from '@on3/ui-lib/components/Svg';
import { useSite } from '@on3/ui-lib/index';
import { getAssetUrl } from '@on3/ui-lib/utils/images';
import {
  getHeaderTheme,
  getSiteChecks,
  HeaderThemes,
} from '@on3/ui-lib/utils/sites';
import clsx from 'clsx';
import { Auth } from 'components/Header/Auth/Auth';
import { KsrHeader } from 'components/Header/Ksr';
import { Menu } from 'components/Header/Menu';
import { MobileMenu } from 'components/Header/MobileMenu/MobileMenu';
import { ScrollWrapper } from 'components/Header/ScrollWrapper';
import { SiteLogo } from 'components/Image/SiteLogo';
import { TeamAvatar } from 'components/Image/TeamAvatar';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  createContext,
  CSSProperties,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import styles, { colorBgGray, colorGray, colorOn3 } from './Base.module.scss';

export type LogoType =
  | 'on3'
  | 'channel'
  | 'elite'
  | 'her'
  | 'nil'
  | 'pro'
  | 'softball'
  | 'team'
  | 'transferPortal'
  | 'highSchool';

interface BaseHeaderProps {
  logo?: LogoType;
}

type ThemeKeys = keyof ReturnType<typeof getSiteChecks>;

interface IHeaderContext {
  logo: JSX.Element;
  secondaryLogo: JSX.Element | null;
  themes: {
    [key in ThemeKeys]: boolean;
  } & {
    active: LogoType;
    header: HeaderThemes;
    style: { '--header-text-color'?: string; '--header-bg-color'?: string };
  };
  showOn3: boolean;
}

export const HeaderContext = createContext<IHeaderContext>({
  logo: <On3 />,
  secondaryLogo: null,
  themes: {
    isOn3: false,
    isKsr: false,
    isHer: false,
    isHighSchool: false,
    isNil: false,
    isPro: false,
    isBetting: false,
    isSoftballAmerica: false,
    isTransferPortal: false,
    isChannel: false,
    isTeam: false,
    isPlayer: false,
    isCoach: false,
    isSearch: false,
    isElite: false,
    isNascar: false,
    isDb: false,
    isLogin: false,
    active: 'on3',
    header: HeaderThemes.DEFAULT,
    style: {},
  },
  showOn3: false,
});

export const Header = ({ logo = 'on3' }: BaseHeaderProps) => {
  const { pathname, query } = useRouter();
  const { menuData, secondaryMenuData, currentSite } = useSite();
  const theme = useTheme();
  const siteChecks = getSiteChecks({ site: currentSite, pathname, query });

  // Determine the logo to render
  const logos = useMemo(
    () => ({
      on3: {
        component: On3,
        attrs: { href: '/', title: 'Home' },
      },
      nil: {
        component: On3IconNilLogo,
        attrs: { href: '/nil/', title: 'Home' },
      },
      elite: {
        component: On3IconOn3Elite,
        attrs: { href: '/elite/', title: 'Home' },
      },
      pro: {
        component: On3Icon5GoatsLogo,
        attrs: { href: '/pro/', title: '5Goats Home' },
      },
      her: {
        component: On3IconHerLogo,
        attrs: { href: '/her/', title: "Women's Home" },
      },
      transferPortal: {
        component: On3IconTransferPortal,
        attrs: { href: '/transfer-portal/', title: 'Transfer Portal Home' },
      },
      highSchool: {
        component: On3IconHighSchoolLogo,
        attrs: { href: `/high-school/`, title: 'High School Home' },
      },
      team: {
        component: SiteLogo,
        attrs: { href: `${currentSite?.url}/`, title: 'Home' },
      },
      softball: {
        component: On3IconSoftballLogo,
        attrs: { href: '/softball/', title: 'Softball America Home' },
      },
      channel: {
        component: (props: { className?: string }) => (
          <div {...props}>
            <TeamAvatar
              src={getAssetUrl(currentSite?.organization?.defaultAssetRes)}
            />
            <div className={styles.name}>
              <span>
                {currentSite?.organization?.knownAs ||
                  currentSite?.organization?.name}
              </span>
            </div>
          </div>
        ),
        attrs: { href: `${currentSite?.url}/`, title: 'Home' },
      },
    }),
    [currentSite],
  );

  const primaryLogoType: LogoType = useMemo(() => {
    if (siteChecks.isSoftballAmerica) return 'softball';
    if (siteChecks.isTeam) return 'team';
    if (siteChecks.isPro || siteChecks.isBetting) return 'pro';
    if (siteChecks.isElite) return 'elite';

    return 'on3';
  }, [siteChecks]);

  const secondaryLogoType: LogoType = useMemo(() => {
    if (siteChecks.isChannel) return 'channel';
    if (siteChecks.isHighSchool) return 'highSchool';
    if (siteChecks.isTransferPortal) return 'transferPortal';
    if (siteChecks.isHer) return 'her';
    if (siteChecks.isNil) return 'nil';

    return 'on3';
  }, [siteChecks]);

  const Logo = useCallback(
    ({ type }: { type: LogoType | null }) => {
      if (!type) return null;

      const pageLogo = logos[type] || logos.on3;
      const Component = pageLogo.component;

      return (
        <Link
          className={styles.logoLink}
          href={pageLogo.attrs.href}
          title={pageLogo.attrs.title}
        >
          <Component
            className={clsx(styles.logo, {
              [styles.on3]: logo === 'on3',
            })}
          />
        </Link>
      );
    },
    [logo, logos],
  );

  const showOn3 = logos[primaryLogoType].attrs.href !== '/';
  const headerTheme = useMemo(() => getHeaderTheme(siteChecks), [siteChecks]);

  // Inline styles based on the site data
  const themeStyles = useMemo(() => {
    if (siteChecks.isPro || siteChecks.isBetting) {
      if (siteChecks.isNascar) {
        return {
          '--header-text-color': theme.appHeader.backgroundColor,
          '--header-bg-color': colorGray,
        };
      }

      return {
        '--header-text-color': colorGray,
        '--header-bg-color': theme.appHeader.backgroundColor,
      };
    }

    if (siteChecks.isChannel) {
      return {
        '--header-text-color': theme.palette.primary.main,
        '--header-bg-color': '#fff',
      };
    }

    if (headerTheme === HeaderThemes.SECONDARY) {
      return {
        '--header-text-color': colorOn3,
        '--header-bg-color': colorBgGray,
      };
    }

    return {
      '--header-bg-color': theme.appHeader.backgroundColor ?? '#fff',
    };
  }, [siteChecks, headerTheme, theme.appHeader, theme.palette]);

  return (
    <HeaderContext.Provider
      value={{
        logo: <Logo type={primaryLogoType} />,
        secondaryLogo: <Logo type={secondaryLogoType} />,
        themes: {
          ...siteChecks,
          active: logo,
          header: headerTheme,
          style: themeStyles,
        },
        showOn3,
      }}
    >
      {siteChecks.isKsr ? (
        <KsrHeader />
      ) : (
        <ScrollWrapper>
          <header
            className={clsx(
              styles.base,
              {
                [styles.isElite]: siteChecks.isElite,
                [styles.isChannel]: siteChecks.isChannel,
                [styles.isTeam]: siteChecks.isTeam,
                [styles.isSoftballAmerica]: siteChecks.isSoftballAmerica,
                [styles.isPro]: siteChecks.isPro,
                [styles.isNascar]: siteChecks.isNascar,
              },
              {
                [styles.themePrimary]: headerTheme === HeaderThemes.DEFAULT,
                [styles.themeSecondary]: headerTheme === HeaderThemes.SECONDARY,
                [styles.themeSite]: headerTheme === HeaderThemes.SITE,
              },
            )}
            data-header-theme={headerTheme}
            style={themeStyles as CSSProperties}
          >
            <div className={styles.primary}>
              <div className={styles.container}>
                <MobileMenu data={menuData} />
                <div className={styles.logoWrapper}>
                  <Logo type={primaryLogoType} />
                </div>
                <Menu data={menuData} />
                <Auth />
              </div>
            </div>
            {secondaryMenuData && (
              <div className={styles.secondary}>
                <div className={styles.container}>
                  {primaryLogoType !== secondaryLogoType && (
                    <div className={styles.logoWrapper}>
                      <Logo type={secondaryLogoType} />
                    </div>
                  )}
                  <Menu data={secondaryMenuData} expanded />
                </div>
              </div>
            )}
          </header>
        </ScrollWrapper>
      )}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  const context = useContext(HeaderContext);

  if (!context) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }

  return context;
};
