import { useAuth, useSite } from '@on3/ui-lib/index';
import clsx from 'clsx';
import { Link } from 'components/Link';

import styles from './AdFreeCta.module.scss';

interface IAdFreeCtaProps {
  className?: string;
  hide?: boolean;
}
export const AdFreeCta = ({ className, hide }: IAdFreeCtaProps) => {
  const { user } = useAuth();
  const hasPremiumAccess = user?.st?.includes('premium');
  const showAdFree = !hide && !!user?.has && !hasPremiumAccess;
  const { currentSite } = useSite();

  if (!showAdFree) {
    return null;
  }

  const isKsr = currentSite?.key === 24;
  const ksrJoinLink = `/sites/${currentSite?.slug}/my/settings/`;
  const isOn3 = currentSite?.resourceId === 'R44';
  const hasSiblingSite = currentSite?.siblingSiteKey;
  const siblingSiteUrl =
    hasSiblingSite && currentSite.isChannel
      ? currentSite.url?.replace('college', 'teams')
      : '';
  const subscribeUrl = isKsr
    ? ksrJoinLink
    : isOn3
      ? '/my/settings/'
      : currentSite?.allowSubs
        ? `${currentSite?.url}/my/settings/`
        : `${siblingSiteUrl}/my/settings/`;

  return (
    <Link className={clsx(styles.block, className)} href={subscribeUrl}>
      <span>Ad-free</span>
      <span className={styles.toggle}>
        <span className={styles.knob} />
      </span>
    </Link>
  );
};
