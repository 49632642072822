import { AssetResponse, DefaultAssetResponse, ImageResponse } from '@on3/api';

function getAssetUrl(
  asset?: AssetResponse & DefaultAssetResponse & ImageResponse,
) {
  if (!asset) return null;

  if (asset.url) {
    return asset.url;
  }

  let url = 'https://';

  url += asset.domain ? asset.domain : 'on3static.com';

  // If the source doesn't start with a slash, add one
  if (!asset.source?.startsWith('/')) {
    url += '/';
  }

  url += asset.source ? asset.source : '/static/on3/avatar.png';

  return url;
}

export { getAssetUrl };
