import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';

import styles from './ScrollWrapper.module.scss';

interface ScrollWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const ScrollWrapper = ({ children, className }: ScrollWrapperProps) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollUpDistance, setScrollUpDistance] = useState(0);
  const threshold = 200;
  const showUpThreshold = 200;

  const controlNavbar = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > threshold) {
      setShow(false);
      setScrollUpDistance(0);
    } else if (currentScrollY < lastScrollY) {
      setScrollUpDistance((prev) => prev + (lastScrollY - currentScrollY));
      if (scrollUpDistance > showUpThreshold) {
        setShow(true);
      }
    }

    setLastScrollY(currentScrollY);
  }, [lastScrollY, scrollUpDistance, showUpThreshold, threshold]);

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);

    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [controlNavbar]);

  return (
    <div
      className={clsx(styles.base, className, {
        [styles.hidden]: !show,
        [styles.scrolled]: lastScrollY > 50,
      })}
    >
      {children}
    </div>
  );
};
